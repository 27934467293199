<template>
  <auth>
    <div class="right-content" id="main-content">
      <h3 class="register-header">Enter your personal information</h3>
      <div class="register-content" v-show="is_loaded">
        <form @submit.prevent="submit" id="register-form">
          <div class="form-group">
            <label for="first_name">First Name*</label>
            <input
              id="first_name"
              v-model="personal.firstname"
              class="form-control"
              type="text"
              name="firstname"
            />
          </div>
          <div class="form-group">
            <label>Middle Name*</label>
            <input
              v-model="personal.middlename"
              class="form-control"
              type="text"
              name="middlename"
            />
          </div>
          <div class="form-group">
            <label>Last Name*</label>
            <input
              v-model="personal.lastname"
              class="form-control"
              type="text"
              name="lastname"
            />
          </div>
          <div class="form-group">
            <label>ID Number*</label>
            <input
              v-model="personal.id_number"
              class="form-control"
              type="text"
              name="id_number"
            />
          </div>

          <div class="form-group">
            <label>Citizenship*</label>
            <select v-model="personal.citizenship" class="form-control" name="citizenship">
              <option value="kenyan">
                Kenyan
              </option>
              <option value="foreigner">
                Foreigner
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Where did you hear about this platform?</label>
            <select v-model="personal.source" class="form-control" name="source">
              <option>Branch</option>
              <option>Email marketing</option>
              <option>Facebook</option>
              <option>SMS</option>
              <option>TV</option>
              <option>Twitter</option>
              <option>Youtube</option>
              <option>Instagram</option>
              <option>Radio</option>
            </select>
          </div>
          <div class="form-group">
            <button class="btn btn-lg btn-block btn-primary">
              Validate
            </button>
          </div>
        </form>
      </div>
      <loading v-if="! is_loaded" />
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },
  data() {
    return {
      personal: {
        firstname: "",
        middlename: "",
        lastname: "",
        id_number: "",
        citizenship: "kenyan",
        source: "",
      },
      is_loaded: true,
    };
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },

  watch: {
    user: {
      handler(oldval, newval) {
        if (oldval !== newval) {
          this.initiate();
        }
      },
    },
  },

  mounted() {
    this.initiate();
  },

  methods: {
    submit() {
      this.is_loaded = false;
      this.$form.load('register-form')

      this.$axios
        .post("/api/v1/verify/personal", this.personal)
        .then(() => {
          this.is_loaded = true;
          this.$store.dispatch("initialize").then(() => {
            this.$router.replace({ name: "verify.phone" });
          });
        })
        .catch((error) => {
          this.$form.validate('register-form', error.response)
        })
        .finally(() => {
          this.is_loaded = true;
        });
    },
    initiate() {
      if (!this.user.email) {
        this.$store.dispatch("initialize");
      }

      if (this.user.requires_password_change) {
        this.$router.replace({ name: "set-password" });
        return;
      }
      if (this.user.verified_phone) {
        this.$router.replace({ name: "dashboard" });
        return;
      }

      if (!this.user.verified_phone && this.user.id_number !== null) {
        this.$router.replace({ name: "verify.phone" });
      }
    },
  },
};
</script>
